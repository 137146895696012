<template>
  <v-container>
    <v-row class="text-left" justify="center">
      <v-col class="col-4">
        <br>
        <p>We believe that a journey of self-improvement is a constant learning process.</p>
        <p>Based on our experience of coaching and mentoring using engineering ladders framework.</p>
        <p>We are developing a tool for you and your colleagues to asses your skills and help you set next targets in your engineerinr career.</p>
      </v-col>
      <v-col class="col-6">
        <img height="400" src="/about-us-future.png">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'AboutUs',
    metaInfo: {
      link: [
        {rel: 'canonical', href: 'https://skills-radar.com/about'}
      ]
    },
    data: () => ({
      //
    })
  };
</script>

<style>
</style>
