<template>
  <v-app>
    <v-content>
      <v-app-bar
        app
        color="primary"
        dark
        hide-on-scroll
      >
        <v-toolbar-title>Skills radar</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="d-flex justify-end">
          <div id="user-avatar" v-show="isSignIn"></div>
          <v-btn icon title="Log out" v-show="isSignIn">
            <GoogleLogin
              :params="paramsGoogleLogin"
              :logoutButton=true
              :onSuccess="onSignOut"
              :onFailure="onSignFailure"
              style="outline: none; padding: 12px 12px"
            >
              <v-icon>fas fa-sign-out-alt</v-icon>
            </GoogleLogin>
          </v-btn>
        </div>
      </v-app-bar>

      <router-view @callGetLink="getLink"></router-view>

      <v-row
        justify="center"
        class="not-print"
      >
        <v-col
          cols="2"
          class="d-flex justify-end"
        >
          <img class="bottom-img" src="~@/assets/girl.svg">
        </v-col>
        <v-col
          cols="6"
          v-if="!isSignIn"
        >
          <!-- <h1
            class="text-center"
          >
            Register Here
          </h1>
          <v-row justify="center" align="center">
            <v-form id="form-row">
              <v-text-field
                v-model="email"
                label="E-mail"
                required
              ></v-text-field>
              <v-btn
                color="primary"
                block
              >
                Continue
              </v-btn>
            </v-form>
          </v-row> -->
          <v-divider style="margin: 40px auto 0 auto; width: 200px;"></v-divider>
          <p  class="font-weight-thin text-center mt-7">
            Login with social networks
            <!--Or register with social login-->
          </p>
          <v-row justify="center">
            <v-btn id="btn-google" v-if="gdpr == 1">
              <GoogleLogin  :params="paramsGoogleLogin" :onSuccess="onSignIn" :onFailure="onSignFailure" :onCurrentUser="onCurrentUser">
                <v-row align=center style="background: #4285f4;color:#fff; border: 1px solid transparent; position: relative; width: 219px;height: 41px;">
                  <v-col cols=auto style="background: #fff;width: 42px;height: 100%;">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                  </v-col>
                  <v-col class="pt-0 pb-0">
                    <span style="vertical-align: middle">Sign in with Google</span>
                  </v-col>
                </v-row>
              </GoogleLogin>
            </v-btn>
            <v-btn id="btn-google" v-else>
              <v-row @click="showModalGDPR" align=center style="background: #4285f4;color:#fff; border: 1px solid transparent; position: relative; width: 219px;height: 41px;">
                <v-col cols=auto style="background: #fff;width: 42px;height: 100%;">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                </v-col>
                <v-col style="position: absolute; font-size: 0.8em;padding-left: 50px;">
                  <span style="vertical-align: middle;">Sign in with Google</span>
                </v-col>
              </v-row>
            </v-btn>
            <v-btn
              id="btn-linkedin"
              @click="getAuthCodeLinkedin"
            >
            </v-btn>
          </v-row>
        </v-col>
        <v-col id="copy-link" ref="postMe" v-if="isSignIn && isShowLink">
          <v-row>
            <v-text-field id='copy-link-input' v-model='copy_link' :hint="copy_link_tooltip_show ? copy_link_tooltip : ''" :persistent-hint="true" placeholder="Your link">
               <v-icon v-if="!isLinkLoaded && !isLinkLoading" slot="append" id='btn-copy-link' v-on:click="getLink">fas fa-sync-alt</v-icon>
               <v-icon v-if="!isLinkLoaded && isLinkLoading" slot="append" id='btn-copy-link'>fas fa-sync-alt fa-spin</v-icon>
               <v-icon v-if="isLinkLoaded" slot="append" id='btn-copy-link' v-on:click="copyLinkToBuffer">fas fa-copy</v-icon>
            </v-text-field>
          </v-row>
          <v-row justify="center" v-if="!isSignInGoogle">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="display: inline-block; margin: 0 3px 0 0; line-height: 36px;width: 105px;"
                  v-bind="attrs"
                  v-on="on"
                  @click="shareInLinkedin"
                >
                  <a style="background: #0077b5;color:#fff;padding: 10px 10px; border-radius: 3px; border: 1px solid #0077b5; position: relative;">
                    <v-icon style="color:#fff; font-size: 15px;">fab fa-linkedin-in </v-icon>
                    <span style="padding: 0 0 0 15px;vertical-align: middle;margin: 1px 0 0;">Share</span>
                    <v-icon v-if="isShareLoading" style="margin-left: 15px;">fas fa-spinner fa-spin</v-icon>
                  </a>
                </v-btn>
              </template>
              <span>Share diagram in LinkedIn</span>
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-start"
        >
          <img class="bottom-img" src="~@/assets/boy.svg">
        </v-col>
      </v-row>
    </v-content>

    <v-footer class="not-print" padless>
      <v-row
      justify="center"
      no-gutters
      >
        <v-btn
          v-for="(link, index) in footerLinks"
          :key="index"
          color="primary"
          text
          rounded
          class="my-2"
          @click="toRoute(link)"
        >
          {{link.title}}
        </v-btn>
        <v-col
          class="text-center"
          cols="12"
        >
          © {{ new Date().getFullYear() }} - <a href="/" style="text-decoration: none;">skills-radar.com</a>
        </v-col>
      </v-row>
    </v-footer>

    <ModalGDPR
      v-show="isModalGDPRVisible"
      @close="closeModalGDPR"
      @accept="acceptModalGDPR"
      class="not-print"
    />
  </v-app>
</template>

<script>
import {googleLogin} from './components/mixins/googleLogin'
import ModalGDPR from './components/ModalGDPR.vue'

let LINKEDIN_CLIENT_ID;
let LINKEDIN_SECRET_KEY;
let LINKEDIN_REDIRECT_URI;

if (process.env.NODE_ENV !== 'production') {
  LINKEDIN_CLIENT_ID = "78g2om1g9aua19"
  LINKEDIN_SECRET_KEY = "Cu34P52kWNuilFvp"
  LINKEDIN_REDIRECT_URI = "http://localhost:8080/auth/linkedin" ///Authorized redirect URLs for app
} else {
  LINKEDIN_CLIENT_ID = "78dzf7kzzuqskg"
  LINKEDIN_SECRET_KEY = "4e8zBHdSRwPke5UT"
  LINKEDIN_REDIRECT_URI = "https://skills-radar.com/auth/linkedin" ///Authorized redirect URLs for app
}

export default {
  name: 'App',

  components: {
    ModalGDPR
  },

  mixins: [googleLogin],

  data: () => ({
    isModalGDPRVisible: false,
    gdpr: 0,
    isLinkLoaded: false,
    isLinkLoading: false,
    copy_link: '',
    copy_link_tooltip_show: false,
    copy_link_tooltip: "",
    email: null,
    isShowLink: false,
    authCodeLinkedin: "",
    idUserLinkedin: "",
    isShareLoading: false,
    currLink: "/",
    footerLinks: [
      {title: "Home", toPage: "HelloWorld", router: "/"},
      {title: "About Us", toPage: "AboutUs", router: "/about"}
    ]
  }),

  mounted () {
    if (localStorage.gdpr == null) {
      localStorage.gdpr = 0;
      this.gdpr = 0;
    } else {
      this.gdpr = localStorage.gdpr;
    }
    if (localStorage.gdpr == 0) {
      this.showModalGDPR();
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('code') != undefined) {
      this.getTokenLinkedin( urlParams.get('code') );
    }

    if (this.isSignInGoogle === false && localStorage.tokenLinkedin) {
      this.onSignInWithLinkedin();
    }
  },

  watch: {
    $route(to/*, from*/) {
      to.name == "user" ? this.isShowLink = true : this.isShowLink = false;
    }
  },

  methods: {
    showModalGDPR () {
      this.isModalGDPRVisible = true;
    },
    closeModalGDPR () {
      this.isModalGDPRVisible = false;
    },
    acceptModalGDPR () {
      localStorage.gdpr = 1;
      this.gdpr = 1;
      this.closeModalGDPR();
    },
    getLink () {
      this.isShowLink = true;
      this.isLinkLoading = true;
      this.axios
        .get(this.SERVER_URL+"/users/url", { headers: { "Authorization": "Bearer " + localStorage.token } })
        .then((res) => {
          if (res.data.result == "error") {
            localStorage.removeItem('token');
          } else {
            this.copy_link = window.location.host + '/u' + res.data.result;
            this.copy_link_tooltip = "Link copied to the clipboard";
            setTimeout(() => this.copy_link_tooltip_show = false, 1500);
            this.isLinkLoaded = true;
            this.isLinkLoading = false;
          }
        })
        .catch((error) => {
          console.error(error);
          this.copy_link_tooltip_show = true;
          this.copy_link_tooltip = "Your link has been loaded. Try updating";
          setTimeout(() => this.copy_link_tooltip_show = false, 1500);
          this.isLinkLoaded = false;
          this.isLinkLoading = false;
        });
    },
    copyLinkToBuffer () {
      document.getElementById('copy-link-input').select();
      document.execCommand("copy");
      this.copy_link_tooltip_show = true;
      setTimeout(() => this.copy_link_tooltip_show = false, 1500);
    },
    getAuthCodeLinkedin () {
      if (this.gdpr == 0) {
        this.showModalGDPR();
        return;
      }
      if (localStorage.token) return;
      window.open("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id="+LINKEDIN_CLIENT_ID+"&redirect_uri="+LINKEDIN_REDIRECT_URI+"&state=fooobar&scope=r_liteprofile%20w_member_social%20r_emailaddress","_self");
    },
    getTokenLinkedin (auth_code) {
      if (this.gdpr == 0) {
        this.showModalGDPR();
        return;
      }
      let data = {
        grant_type: "authorization_code", code: auth_code, redirect_uri: LINKEDIN_REDIRECT_URI, client_id: LINKEDIN_CLIENT_ID, client_secret: LINKEDIN_SECRET_KEY
      };
      this.axios
        .post(this.SERVER_URL+"/tokenlinkedin", data)
        .then((res) => {
          localStorage.tokenLinkedin = res.data.token;
          this.$router.push({ path: `/` });
          this.$router.go(0);
        })
        .catch((error) => {
          console.error(error);
          this.$router.push({ path: `/` });
        });
    },
    onSignInWithLinkedin () {
      if (this.gdpr == 0) {
        this.showModalGDPR();
        return;
      }
      let data = {
        token: localStorage.tokenLinkedin,
      };
      this.axios
        .post(this.SERVER_URL+"/profilelinkedin", data)
        .then((res) => {
          let preferredLocale = res.data.profile.firstName.preferredLocale.language + "_" + res.data.profile.firstName.preferredLocale.country;
          let firstName = res.data.profile.firstName.localized[preferredLocale];
          let lastName = res.data.profile.lastName.localized[preferredLocale];
          let avatar = res.data.profile.profilePicture['displayImage~'].elements[0].identifiers[0].identifier;
          this.idUserLinkedin = res.data.profile.id;
          let userAvatar = document.getElementById('user-avatar');
          userAvatar.style.backgroundImage = `url(${avatar})`;

          let email = res.data.email.elements[0]["handle~"].emailAddress;
          let data = {
            username: firstName+" "+lastName, email: email
          };
          if (localStorage.token == undefined) {
            this.axios
              .post(this.SERVER_URL+"/users", data)
              .then((res) => {
                localStorage.token = res.data.token;
                if (document.location.pathname == '/') this.$router.push({ path: `/u/${this.idUserLinkedin}` });
                // TODO: use different uuid because idUserLinkedin is ugly
                localStorage.uuid = this.idUserLinkedin;
                this.isSignIn = true;
                this.getLink();
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            if (document.location.pathname == '/') this.$router.push({ path: `/u/${this.idUserLinkedin}` });
            this.isSignIn = true;
          }

        })
        .catch((error) => {
          console.error(error);
        });
    },
    async shareInLinkedin () {
      if (this.gdpr == 0) {
        this.showModalGDPR();
        return;
      }
      this.isShareLoading = true;
      let base64Canvas = await this.$html2canvas(document.querySelector(".radar-share"), {type: 'dataURL'});
      let data = {
        token: localStorage.tokenLinkedin, id: this.idUserLinkedin, image: base64Canvas.replace(/^data:image\/[a-z]+;base64,/, ""), link: this.copy_link
      };
      this.axios
        .post(this.SERVER_URL + "/shareinlinkedin", data, { headers: { "Authorization": "Bearer " + localStorage.token } })
        .then((res) => {
          if (res.data.result == "error") {
            localStorage.removeItem('token');
          }
          this.isShareLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isShareLoading = false;
        });
    },
    toRoute (link) {
      if (link.router == "/" && localStorage.uuid)
        this.$router.push({ path: `/u/${localStorage.uuid}` });
      else this.$router.push({ path: link.router });
      this.$router.go(0);
    }
  }
};
</script>

<style>
  * {
    font-family: Joanna, Helvetica Now, Helvetica, Arial, sans-serif;
  }

  #user-avatar {
    height:50px;
    width:50px;
    border-radius:50%;
    background:white;
    background-size:100%;
  }
  .bottom-img {
    min-width: 10px;
    max-height: 250px;
  }
  #form-row {
    width: 400px;
    max-width: 400px;
  }
  #copy-link {
    max-width: 300px;
    min-width: 200px;
  }
  .v-input {
    z-index: 2;
  }
  #btn-copy-link {
    cursor: pointer;
    z-index: 2;
  }
  #btn-google {
    margin-right: 20px;
    width: 219px;
    height: 41px;
  }
  #btn-google:hover {
    box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
  }
  #btn-google:active {
    background-color: #3367d6;
  }
  #btn-linkedin {
    width: 219px;
    height: 41px;
    background-image: url("~@/assets/linkedin_default.png");
    background-size: 100% 100%;
  }
  #btn-linkedin:hover {
    background-image: url("~@/assets/linkedin_hover.png");
  }

  @media (min-width: 320px) and (max-width: 908px) {
    #btn-google {
      margin-right: 0px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 667px) {
    .bottom-img {
      display: none;
    }

  }

  /* print styles */
  @media print {
    @page {
      size: auto;
      margin: 0 30px 0 30px;
    }
    * {
      -webkit-print-color-adjust: exact;
    }
    canvas {
      max-width: 500px;
      max-height: 500px;
    }
    .not-print, header, #ladders, .v-input__slider {
      display: none !important;
    }
    #legend {
      padding-bottom: 30px;
    }
    .subheading {
      margin-top: -30px;
    }
    .slider, .desc {
      margin-top: -50px;
    }
  }
</style>
