import GoogleLogin from 'vue-google-login'

let CLIENT_ID;
if (process.env.NODE_ENV !== 'production') {
  CLIENT_ID = "475139355614-qq1p2f0r6lfpkjsdrn9f8664fci0o509.apps.googleusercontent.com";
} else {
  CLIENT_ID = "386704570409-mlj7hbnechle8of11jaqjr7q4lgo81gk.apps.googleusercontent.com";
}

export const googleLogin = {
  components: {
    GoogleLogin
  },
  data: () => ({
    paramsGoogleLogin: {
      client_id: CLIENT_ID,
    },
    renderParamsGoogleLogin: {
      width: 130,
      height: 40
    },
    isSignIn: false,
    isSignInGoogle: false,
  }),
  methods: {
    onSignIn(googleUser) {
      if (localStorage.gdpr == 0) {
        this.showModalGDPR();
        return;
      }
      let data = {
        username: googleUser.getBasicProfile().getName(), email: googleUser.getBasicProfile().getEmail()
      };
      this.axios
        .post(this.SERVER_URL+"/users", data)
        .then((res) => {
          localStorage.token = res.data.token;
          if (document.location.pathname == '/') {
            this.$router.push({ path: `/u/${googleUser.getBasicProfile().getId()}` });
            localStorage.uuid = googleUser.getBasicProfile().getId();
          }
          this.$router.go(0);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onSignOut() {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenLinkedin');
      localStorage.removeItem('uuid');
      this.$router.go(0);
    },
    onSignFailure(error) {
      console.log(error);
    },
    onCurrentUser(googleUser) {
      if (localStorage.gdpr == 0) {
        this.showModalGDPR();
        return;
      }
      if (googleUser) {
        if (localStorage.token) {
          this.isSignIn = true;
          this.isSignInGoogle = true;
          let userAvatar = document.getElementById('user-avatar');
          userAvatar.style.backgroundImage = "url('"+googleUser.getBasicProfile().getImageUrl()+"')";
          localStorage.uuid = googleUser.getBasicProfile().getId();
        } else {
          this.onSignIn(googleUser);
        }

      } else {
        localStorage.removeItem('token');
      }
    }
  }
}